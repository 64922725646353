import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { useGetTrendingQuery, useGetPopularQuery, useGetDealsQuery } from '../../services/homepage';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';
import HomeData from '../../Components/HomeData';
import 'react-lazy-load-image-component/src/effects/blur.css';
import HomeCarousel from '../../Components/HomeCarousel';
import HomeProductCards from '../../Components/HomeProductCards';
import HelmetComponent from '../../Components/HelmetComponent';
import HomeDeals from '../../Components/HomeDeals';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    }, /* Chrome */
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

function Home() {
  const { t } = useTranslation()
  const { userId, userToken, userEmail, languageCode, countryCode, cartToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const trendingData = useGetTrendingQuery({ countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const popularData = useGetPopularQuery({ countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const dealsData = useGetDealsQuery({ countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  const classes = useStyles();
  let adcPosition
  if (document.body.dir === 'rtl') {
    adcPosition = { left: '10px' }
  } else {
    adcPosition = { right: '10px' }
  }

  function bannerCategories() {
    return (
      <div className="container">
        <ImageList className={classes.imageList} cols={8}>
          {HomeData?.category_a?.map((item, i) =>
            <ImageListItem className="cardItem" key={i}>
              <Link to={`/search/${item.link}`} className="cardItem-item" style={{ border: 'none' }}>
                <div className='img-card categories-card'>
                  <img
                    style={{ padding: '0px', borderRadius: '8px' }}
                    className="img-item"
                    src={item?.image}
                    alt={item?.name}
                  />
                </div>
              </Link>
              {item.name &&
                <div className="card-content" style={{ height: 'auto', whiteSpace: 'nowrap' }}>
                  <h4>{t(item.name)}</h4>
                </div>
              }
            </ImageListItem>
          )}
        </ImageList>
      </div>
    )
  }


  function trendingCards() {
    return (
      <div className="container">
        <HomeProductCards productData={trendingData.data?.trendings} isLoading={trendingData?.isLoading} heading={t('trending')} />
      </div>
    )
  }

  return (
    <>
      <HelmetComponent title={'Home'} />
      <div className="hp-style">
        <div className="container">
          <HomeCarousel />
        </div>


        {/* <div className="container">
          <HomeDeals productData={dealsData.data?.deals} isLoading={dealsData?.isLoading} heading={t('Flash Deals')} />
        </div> */}


        <div style={{ padding: '1px' }}></div>
        <>
          {trendingCards()}
          <br />
          {bannerCategories()}
        </>
        <div style={{ padding: '10px' }}></div>
        <div className="container">
          <ImageList className={classes.imageList} cols={6}>
            {HomeData?.category_b?.map((item, i) =>
              <ImageListItem className="cardItem" key={i}>
                <Link to={`/search/${item.link}`} className="cardItem-item" style={{ border: 'none' }}>
                  <div className='img-card'>
                    <img
                      style={{ padding: '0px', borderRadius: '8px', height: 'auto' }}
                      className="img-item"
                      src={item?.image}
                      alt={item?.name}
                    />
                  </div>
                </Link>
                {item.name &&
                  <div className="card-content" style={{ height: 'auto' }}>
                    <h4>{t(item.name)}</h4>
                  </div>
                }
              </ImageListItem>
            )}
          </ImageList>
        </div>
        <div className="container">
          <HomeProductCards productData={popularData.data?.popular} isLoading={popularData?.isLoading} heading={t('popular')} />
        </div>
        <div style={{ padding: '10px' }}></div>
        <div className="container">
          <ImageList className={classes.imageList} cols={6}>
            {HomeData?.category_c?.map((item, i) =>
              <ImageListItem className="cardItem" key={i}>
                <Link to={`/search/${item.link}`} className="cardItem-item" style={{ border: 'none' }}>
                  <div className='img-card'>
                    <img
                      style={{ padding: '0px', borderRadius: '8px', height: 'auto' }}
                      className="img-item"
                      src={item?.image}
                      alt={item?.name}
                    />
                  </div>
                </Link>
                {item.name &&
                  <div className="card-content" style={{ height: 'auto' }}>
                    <h4>{t(item.name)}</h4>
                  </div>
                }
              </ImageListItem>
            )}
          </ImageList>
        </div>

        <div style={{ padding: '10px' }}></div>
        <div className="container">
          <ImageList className={classes.imageList} cols={6}>
            {HomeData?.fashion?.map((item, i) =>
              <ImageListItem className="cardItem" key={i}>
                <Link to={`/search/${item.link}`} className="cardItem-item" style={{ border: 'none' }}>
                  <div className='img-card'>
                    <img
                      style={{ padding: '0px', borderRadius: '8px', height: 'auto' }}
                      className="img-item"
                      src={item?.image}
                      alt={item?.name}
                    />
                  </div>
                </Link>
                {item.name &&
                  <div className="card-content" style={{ height: 'auto' }}>
                    <h4>{t(item.name)}</h4>
                  </div>
                }
              </ImageListItem>
            )}
          </ImageList>
        </div>
        <div style={{ padding: '10px' }}></div>
      </div>
    </>
  );
}

export default Home;
