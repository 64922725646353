import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import 'react-phone-number-input/style.css'
import { Tag } from "antd";
import formatPrice from '../formatPrice';
import IconButton from '@mui/joy/IconButton';
import { useDeleteMutation } from '../../services/cart';
import Loader from '../Loader';
import CloseIcon from '@mui/icons-material/Close';
var moment = require('moment');

function PurchaseItems({ cartData, userData, purchaseresult }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, guestShippment, guestPayment } = useSelector((state) => state.auth);
  const { t } = useTranslation()

  const [deletecart, deleteresult] = useDeleteMutation()



  function getDeliverydays(data) {
    const deliveryDaya = Number(data) + Number(userData?.contant?.delivery_days || 0)
    function getTimeline() {
      if (Number(deliveryDaya) === 1) {
        return 'Tomorrow'
      } else {
        return ''
      }
    }
    if (Number(deliveryDaya) > 0) {
      return <><strong>{getTimeline()}</strong> {moment().add(Number(deliveryDaya), 'days').format('dddd Do MMM')}</>
    } else {
      return moment().add(10, 'days').format('dddd Do MMM YYYY')
    }
  }

  const groupData = cartData?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  const soteIds = [3, 1]

  function functionIdLink(id, pid, store_id) {
    if (soteIds?.includes(Number(store_id))) {
      return pid
    } else {
      return id
    }
  }

  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s.,]/gi, ' ')?.replace(/ /g, '-')
  }


  const verifyProduct = (store_id, deleted, in_stock, value, product_value) => {
    if (Number(store_id) === 3) {
      if (deleted === 1) {
        return true;
      }
      if (in_stock === 0) {
        return true;
      }
      if (Number(value) > Number(product_value)) {
        if (Math.abs(value - product_value) <= 5) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
    return false;
  };


  // const verifyProduct = (store_id, deleted, in_stock, value, product_value) => {
  //   store_id = Number(store_id);
  //   deleted = Number(deleted);
  //   in_stock = Number(in_stock);
  //   value = Number(value);
  //   product_value = Number(product_value);
  //   if (store_id !== 3) return false;
  //   if (deleted === 1 || in_stock === 0 || value > product_value) {
  //     return true;
  //   }
  //   return false;
  // };


  return (
    <>
      {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
        <div key={group} style={{ background: '#f1f1f1', padding: '20px', borderRadius: '10px', marginBottom: '15px' }}>
          <div style={{ fontSize: '12px', fontWeight: 600, paddingBottom: '10px' }}>{t('delivery_by')}&nbsp;{getDeliverydays(group)}</div>
          <div>
            {groupItems?.map((item, i) =>
              <div className="col-md-12"
                style={{ marginBottom: '15px', padding: '10px', background: '#fff', border: purchaseresult?.error?.data?.mismatch && verifyProduct(item.store_id, item.deleted, item.in_stock, item.value, item.product_value) ? '0.7px solid tomato' : '0.7px solid #00000026', borderRadius: '10px' }} key={i}>
                <div className="cart-page-card">
                  <div className="img-card">
                    <img
                      style={{ minHeight: '80px', maxHeight: '80px', minWidth: '80px', maxWidth: '80px' }}
                      className="img-item"
                      src={item.image}
                      loading="lazy"
                    />
                  </div>
                  <div className="cart-page-text" style={{ margin: '0 5px', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <div className="brand" style={{ fontSize: '10px' }}>By {item.brand}</div>
                    </div>
                    <Link to={`/${languageCode}/product/${item.store_id}/${functionIdLink(item?.product_id, item?.pid, item?.store_id)}/${productTitle(item.title)}`}>
                      <div className="title ellipsis-title-2" style={{ fontSize: '10px' }}>{item.title?.replace(/[^\w\s.,']/gi, ' ')}</div>
                    </Link>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="price" style={{ fontSize: '12px', padding: 0 }}>
                        {formatPrice(item.prices?.value, item.prices?.currency, 0)}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {item.color && <div style={{ fontSize: '10px', margin: '0px 10px' }}>color. &nbsp;{item.color}</div>}
                        <div style={{ fontSize: '10px', margin: '0px 10px' }}>Qty. &nbsp;{item.quantity}</div>
                      </div>
                    </div>

                    <IconButton
                      disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id}
                      onClick={(() => deletecart({
                        id: item.id,
                        cartToken: cartToken,
                        countryCode: countryCode,
                        userId: userId,
                      }))}
                      style={{ position: 'absolute', right: 0, top: 0, zIndex: 111, minHeight: '20px', height: '20px', borderRadius: '4px' }}
                      variant='outlined'
                      size='sm'>
                      {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                        <Loader /> :
                        <CloseIcon style={{ fontSize: '14px', color: 'tomato' }} />
                      }
                    </IconButton>

                    {purchaseresult?.error?.data?.mismatch &&
                      <>
                        {verifyProduct(item.store_id, item.deleted, item.in_stock, item.value, item.product_value) &&
                          <>
                            <small style={{ color: 'tomato', fontSize: '12px' }}>Not Available</small>
                          </>
                        }
                      </>
                    }

                    {item.current_variant &&
                      <div style={{ padding: '5px 0px 5px 0px' }}>
                        {Object.entries(item.current_variant || []).map(([key, value], index) => (
                          <Tag style={{ fontSize: '10px' }} key={index}><strong>{key}:</strong> {value}</Tag>
                        ))}
                      </div>
                    }
                  </div>
                </div>

              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default PurchaseItems;
