import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Host from '../../Components/Host';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useFetchQuery, useCreateMutation, useUpdateMutation, useDeleteMutation } from '../../services/cart';
import { useGetUserDataQuery } from '../../services/login';
import _, { isArray } from "lodash"
import Loader from '../../Components/Loader'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from 'react-bootstrap'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Rating from '@mui/material/Rating';
import CartPage from '../../Pages/CartPage';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Variation from './Variation';
import Button from '@mui/joy/Button';
import formatPrice from '../formatPrice';
var moment = require('moment');

function ProductFeatures({ productData, productVarData, productFetching, cartButton, asin, title, id, variant, isLoading, isFetching, productNotAvailable, isVarLoading, isVarFetching }) {
  const { userId, userToken, cartToken, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation()
  const [getQuantity, setQuantity] = useState(1);

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  const [getFeaturesLength, setFeaturesLength] = useState(false);
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const deliveryDaya = Number(productVarData?.estimated_delivery) + Number(userData?.contant?.delivery_days || 0)
  function getTimeline() {
    if (Number(deliveryDaya) === 1) {
      return 'Tomorrow'
    } else {
      return ''
    }
  }

  function getDeliverydays() {
    if (Number(deliveryDaya) > 0) {
      return <span style={{ fontWeight: 500 }}>{getTimeline()} {moment().add(Number(deliveryDaya), 'days').format('ddd Do MMM')}</span>
    } else {
      return <span style={{ fontWeight: 500 }}>{moment().add(7, 'days').format('dddd Do MMM YYYY')}</span>
    }
  }

  const getCart = useFetchQuery({ userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const [createNew, createresult] = useCreateMutation()
  const findCart = getCart?.data?.cart?.find((l) => l.product_id === productData?.id)
  const [geturnModal, returnModal] = useState(false);

  function returnPolicyModal() {
    returnModal(true)
  }

  function closeModal() {
    returnModal(false)
  }

  const [getcartDrawer, cartDrawer] = useState();
  useEffect(() => {
    if (createresult?.isSuccess) {
      if (document.body.dir === 'rtl') {
        cartDrawer('left', true)
      } else {
        cartDrawer('right', true)
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: productData?.prices?.currency,
          value: Number(productData?.prices?.value),
          items: [
            {
              item_id: productData?.id,
              item_name: productData?.title,
              item_brand: productData?.brand,
              item_category: productData?.category,
              price: Number(productData?.prices?.value),
              quantity: productData?.quantity
            }
          ]
        }
      });
    }
  }, [createresult])

  const [getShowTitle, setShowTitle] = useState(false);
  const productDetails = getShowTitle ? productData?.title : productData?.title?.substring(0, 220)
  const words = productData?.title?.match(/[A-Za-z]/g);

  const preventStore = [3];
  const numbersQnty = [1, 2, 3, 4]
  function getStockQnty(quantity) {
    if (numbersQnty?.includes(Number(quantity))) {
      return (
        <span style={{ color: 'tomato' }}>Only {quantity} stock left</span>
      )
    } else if (Number(quantity) === 0) {
      return (
        <span style={{ color: 'tomato' }}>Out of stock</span>
      )
    } else {
      return (
        <span style={{ color: '#02732f' }}>
          In Stock <CheckOutlinedIcon style={{ fontSize: '15px', color: '#02732f' }} />
        </span>
      )
    }
  }

  return (
    <div className="purchase-info-sub-card">
      <CartPage getcartDrawer={getcartDrawer} cartDraweraa={cartDrawer} />
      <Modal style={fontFamily} className="refund-modal" show={geturnModal} onHide={closeModal} animation={true}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <p id="#return" style={{ marginBottom: '11px' }}><span style={{ fontSize: '11pt' }}><span style={{ lineHeight: '107%' }}><strong>Returns, Warranty and Refunds</strong></span></span></p>
                <ol style={{ padding: '10px' }}>
                  <li style={{ marginLeft: '8px' }}><span style={{ fontSize: '11pt' }}><span style={{ lineHeight: '107%' }}><span>If an item is defective or damaged on arrival. Contact us to get it returned or refunded immediately within 3 days of receiving the item. </span></span></span></li>
                  <li style={{ marginLeft: '8px' }}><span style={{ fontSize: '11pt' }}><span style={{ lineHeight: '107%' }}><span>Warranty coverage of an item if provided by the manufacturer is provided for 1 year and we shall cover expenses of shipping an item back (unless it is a customized order).</span></span></span></li>
                </ol>
                <Link to="/returns-and-refund" >Learn More</Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className='pg-price' style={{ color: '#000000c4', fontSize: '1.4rem', margin: '0px 3px', lineHeight: 1.1 }}>
        {productFetching ?
          <Skeleton variant="rect" style={{ height: '16px', width: '12%' }} /> :
          <>
            {productData ?
              <>
                <h5>
                  {formatPrice(productData?.prices?.value, productData?.prices?.currency ? productData?.prices?.currency : userData?.contant?.currency, 0)}
                  {productData?.prices?.deal_value &&
                    <small style={{ color: '#5e8e3e', margin: '0px 5px' }}>
                      <small style={{ textDecoration: 'line-through', }}>{productData?.prices?.price}</small> 50% Off
                    </small>
                  }
                </h5>
              </>
              :
              <div
                style={{ textAlign: 'start', fontSize: '1.2rem', color: 'tomato' }}>
                {t('product_not_available')}
              </div>
            }
          </>
        }
      </div>
      {productData?.brand &&
        <div className='product-brand' style={{ fontSize: '14px', paddingBottom: 0, margin: '0px 3px' }}>
          <span className='value'>By {productData?.brand?.toLowerCase()}</span>
        </div>
      }
      <h1 className='product-title' style={{ margin: '0px 3px', wordBreak: 'auto-phrase' }}>
        {title ? title?.replace(/-/g, " ")?.replace(/[^\w\s.,'/:]/gi, ' ') : productDetails?.replace(/[^\w\s.,'/:]/gi, ' ')}
        {productData?.title?.length > 220 &&
          <small
            onClick={(() => setShowTitle(getShowTitle ? false : true))}
            style={{ fontSize: '12px', cursor: 'pointer', color: '#007bff', margin: '0px 10px' }}>
            {getShowTitle ? t('less') : t('more')}
          </small>
        }
      </h1>
      {isLoading ?
        <Skeleton variant="rect" style={{ height: '15px', width: '30%', margin: '0px 3px' }} /> :
        <>
          <div className='product-brand pp-mblock' style={{ fontSize: '14px', display: 'flex', alignItems: 'center', margin: '0px 3px' }}>
            {productData?.condition &&
              <div>
                <span style={{ fontWeight: 500 }}>{t('condition')}:</span>
                <span className='value' style={{ margin: '0px 5px' }}>{productData?.condition?.toLowerCase()} </span>
              </div>
            }
            {productData?.rating && Number(productData?.rating) > 0 ?
              <div className='pp-mmargin' style={{ margin: productData?.condition ? '0px 10px' : 0, display: 'flex', alignItems: 'center', direction: 'ltr' }}>
                <span className='value'>{Number(productData?.rating) > 5 ? 5 : productData?.rating}</span>
                <Rating
                  style={{ color: '#fe7f4a', fontSize: '1rem', margin: '0px 2px', marginTop: '-1.5px' }}
                  name="read-only" value={Number(productData?.rating) > 5 ? 5 : productData?.rating} precision={0.1} readOnly />
              </div> : ''
            }
            <div
              className='pp-mmargin'
              onClick={(() => returnPolicyModal())}
              style={{ margin: (productData?.condition) || (productData?.rating && Number(productData?.rating) > 0) ? '0px 10px' : 0, display: 'flex', alignItems: 'center', border: 'none', cursor: 'pointer' }}>
              <span className='value' style={{ textDecoration: 'underline' }}>{Host?.domainName} {t('protect')}</span>
              <InfoOutlinedIcon style={{ fontSize: '1rem', margin: '0px 2px', color: '#8a8a8a' }} />
            </div>
          </div>
        </>
      }

      {isLoading ?
        <Skeleton variant="rect" style={{ height: '15px', width: '30%', margin: '0px 3px' }} /> :
        <>
          <div className='product-brand pp-mblock' style={{ fontSize: '14px', display: 'flex', alignItems: 'center', margin: '0px 3px' }}>
            <div className='product-brand pp-mmargin'>
              <div style={{ fontWeight: 500, fontSize: '14px', display: 'flex', alignItems: 'center' }}>
                <div>{t('availability')}:</div>
                <div className='value' style={{ margin: '0 10px' }}>
                  {productFetching ?
                    <Skeleton variant="rect" style={{ height: '15px', width: '71px' }} /> :
                    <>
                      {productNotAvailable ?
                        <>
                          {productData?.variants ?
                            <div style={{ color: 'tomato', fontSize: '1rem', textTransform: 'none' }}>{t('currently_not_available')}</div> :
                            <span style={{ color: 'tomato' }}>{t('out_of_stock')}</span>
                          }
                        </>
                        :
                        <>
                          {getStockQnty(productData?.inventory ? productData?.qty : productData?.quantity)}
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
            {!preventStore?.includes(Number(productData?.store_id)) &&
              <div className='button-margin-dm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Link
                  className="pp-view-store"
                  to={`/store/${productData?.store_id}`}
                  style={{ fontSize: '12px', fontWeight: 400, color: '#007bff', borderRadius: '4px' }}
                >
                  {t('view_store')}
                </Link>
              </div>
            }
          </div>
        </>
      }

      <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '10px 5px 10px 5px' }}>
        <div style={{ fontSize: '13px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>Split in 4 payments of </span>
          <span style={{ display: 'flex', alignItems: 'center', margin: '0px 5px' }}>{productFetching ? <Skeleton variant="rect" style={{ height: '12px', width: '50px' }} /> :
            formatPrice(Number(productData?.prices?.value) / 4, productData?.prices?.currency ? productData?.prices?.currency : userData?.contant?.currency, 0)}.</span>
          <span style={{ display: 'flex', alignItems: 'center' }}>No interest with &nbsp;</span>
          <span style={{ display: 'flex', alignItems: 'center' }}><img src='/tabby-logo-1.png' style={{ width: '40px' }} /></span>
        </div>
      </div>

      <Variation productData={productData} productVarData={productVarData} isLoading={isLoading} isFetching={isFetching} id={id} variant={variant} isVarLoading={isVarLoading} isVarFetching={isVarFetching} />
      {!productNotAvailable &&
        <div className='atc-button'>
          <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #e0e0e0', justifyContent: 'space-between', height: '40px', borderRadius: '4px', background: '#fcfcfc', whiteSpace: 'nowrap', margin: '0px 3px' }}>
            <IconButton
              aria-label="delete"
              onClick={(() => getQuantity > 1 && setQuantity(Number(getQuantity) - Number(1)))}
            >
              <RemoveIcon />
            </IconButton>
            <div style={{ padding: '0px 5px 0px 5px' }}>{getQuantity}</div>
            <IconButton
              disabled={productData?.inventory ? Number(getQuantity) === productData?.qty : Number(getQuantity) === 20}
              aria-label="delete"
              onClick={(() => setQuantity(Number(getQuantity) + Number(1)))}
            >
              <AddIcon />
            </IconButton>
          </div>
          {userData?.contant?.currency &&
            <div className='atc-button-item' style={{ margin: '0 20px' }}>
              {[document.body.dir === 'rtl' ? 'left' : 'right'].map((anchor) => (
                <IconButton
                  style={{ width: 'auto', height: '40px', background: Host?.bgColor, color: Host?.buttonColor, padding: '6px 16px', textTransform: 'capitalize', borderRadius: '4px', fontSize: '15px' }}
                  disabled={cartButton}
                  variant="contained"
                  key={anchor}
                  onClick={(() => findCart ? cartDrawer(anchor, true) :
                    createNew({
                      id: productData?.id,
                      in_stock: productVarData?.in_stock,
                      value: productVarData?.price,
                      deleted: productVarData?.status,
                      quantity: getQuantity,
                      cartToken: cartToken,
                      userId: userId,
                      countryCode: countryCode
                    }))}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {createresult?.isLoading ? <Loader /> :
                      <img src={`/assets/svgs/cart.svg`} style={{ width: '18px' }} />
                    }
                    <span style={{ margin: '0 10px' }}>
                      {findCart ? t('added_to_cart') : t('add_to_cart')}
                    </span>
                  </div>
                </IconButton>
              ))}
            </div>
          }
        </div>
      }
      <div className='pg-section-aatc' style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '10px 0px 0px 0px', margin: '0px 3px' }}>
        {!isLoading &&
          <>
            <Button
              variant='outlined'
              disabled
              className='alert alert-info pp-tag-style'
              style={{ margin: 0, borderRadius: 0, borderRadius: '4px', border: '1px solid #fe7f4a69', padding: '0px 10px', minHeight: '35px', height: '35px' }}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', color: '#6d6d6d' }}>
                <LocalShippingOutlinedIcon />
                <div style={{ display: 'flex', alignItems: 'center', margin: '0px 5px' }}>
                  <div>
                    {t('delivery_by')}
                  </div>
                  <div style={{ margin: '0 5px' }}>
                    {getDeliverydays()}
                  </div>
                </div>
              </div>
            </Button>
          </>
        }
        {!isLoading &&
          <Button
            disabled
            variant='outlined'
            className='alert alert-info pp-tag-style mrl10'
            style={{ borderRadius: 0, borderRadius: '4px', border: '1px solid #fe7f4a69', padding: '0px 10px', minHeight: '35px', height: '35px' }}>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', color: '#6d6d6d' }}>
              <div>{t('imported_from')}</div>
              <div style={{ display: 'flex', alignItems: 'center', margin: '0 5px' }}>
                <img
                  style={{ width: '22px', objectFit: 'contain' }}
                  loading="lazy"
                  src={`https://flagcdn.com/w20/${productData?.country?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${productData?.country?.toLowerCase()}.png 2x`}
                  alt=""
                />
                <span style={{ color: '#6d6d6d', fontSize: '14px', margin: '0 5px' }}>{productData?.country}</span>
              </div>
            </div>
          </Button>
        }
      </div>

      <div className="Page-card" style={{ margin: '0px 3px' }}>
        {productData?.features && productData?.features?.length > 0 &&
          <div className='product-brand' style={{ fontSize: '14px', fontWeight: 500 }}>
            <br />
            {t('product_features')}:
          </div>
        }
        {isLoading ?
          <>
            <Skeleton variant="rect" height={'15px'} width={'60%'} />
            <Skeleton variant="rect" height={'15px'} width={'60%'} />
            <Skeleton variant="rect" height={'15px'} width={'40%'} />
          </>
          :
          <ul className="Page-card-header" style={{ padding: '0px 12px 0px 12px' }}>
            {productData?.features && productData?.features?.length > 0 &&
              <>
                {productData?.features?.slice(0, getFeaturesLength ? 100 : 3)?.map((item, i) =>
                  <li style={{ color: '#6b6b6b', fontSize: '13px', wordBreak: 'auto-phrase' }} key={i}>
                    {item?.replace(/�/g, "'")}
                  </li>
                )}
                {productData?.features?.length > 3 &&
                  <div
                    onClick={(() => setFeaturesLength(getFeaturesLength ? false : true))}
                    style={{ fontSize: '12px', cursor: 'pointer', color: '#97c3f0' }}>
                    {getFeaturesLength ? t('show_less') : t('show_more')}
                  </div>
                }
              </>
            }
          </ul>
        }
      </div>
    </div>
  );
}

export default ProductFeatures;
